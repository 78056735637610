<template>
    <div class="d-flex flex-column min-vh-100">
        <Header :showLogout="true" :profile="profile"/>
        <div class="container">
            <div class="row">
                <div class="col-3">
                    <div class="list-group">
                        <router-link to="/admin" class="list-group-item"><i class="fa fa-home"></i> <span>Inicio</span></router-link>

                        <router-link to="/admin/users" class="list-group-item"><i class="fa fa-book"></i> <span>Usuarios</span></router-link>
                        <router-link to="/admin/coupons" class="list-group-item"><i class="fa fa-credit-card"></i> <span>Cupones</span></router-link>
                        <router-link to="/admin/blog" class="list-group-item"><i class="fa fa-edit"></i> <span>blog</span></router-link>
                        <router-link to="/admin/me" class="list-group-item active"><i class="fa fa-key"></i> <span>Mis datos</span></router-link>

                    </div>
                </div>
                <div class="col-7">
                    <h2>Mis datos</h2>
                    <div class="">
                        <table>
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Email</th>
                                    <th>Inscrito</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="user in users" :key="user.id">
                                    <td>{{user.name}}</td>
                                    <td>{{user.email}}</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
<style scoped>
 .list-group {

     margin:auto;
     float:left;
     padding-top:20px;
    }
    .card-horizontal {
    display: flex;
    flex: 1 1 auto;
}
</style>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Users from '@/api/Users';

export default {
  components:{
      Header,
      Footer
  },
  name: 'Profile',
  data() {
      return {
          users: Users.all(),
          profile: this.$store.getters.getUser
      }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-darkBlue{
    background: #152F44
}
.btn-black{ background:#000; color:#FFF; font-size:14px; border:1px solid #979797;}
.btn-black:hover{color:#FFF; background:#333232}
.btn-golden{ background:#AA8D5D; color:#FFF; font-size:14px; border:1px solid #9B7676;}
.btn-golden:hover{color:#FFF; background:#C4A167}

.navbar-light .navbar-nav .nav-link{ color:#FFF}
.navbar-light .navbar-nav .nav-link:hover{ background:#C4A167}
.btn-outline-white{
    border:1px solid #FFF;
    color:#FFF
}
.carousel-caption {top:30%}
.carousel-caption h5{ margin-bottom:30px}
.section{margin:60px 0}
footer{margin-top:60px}
.subheader{ background:#D1C1A7; color:#FFF; display: flex;
  align-items: center;
  justify-content: center; height:250px}
  .course:hover{
      opacity:.8
  }
</style>
