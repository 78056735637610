import axios from 'axios';
const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'application/json',
};

const API_URL = process.env.VUE_APP_APIURL || '';

const apiService = axios.create({
    timeout: 30000,
    headers,
    baseURL: API_URL,
  });

  apiService.interceptors.response.use(
    (response) => {
      if (!response || typeof response === 'undefined') {
        return { success: false };
      }
      if ('data' in response) {
        return response.data;
      }
      return response;
    },
    (error) => {
      if (!error.response) {
        return null;
      }
      if (error.response.status === 401) {
        console.log('TOKEN ERROR - RELOADING');
        //const store = require('@/store/index'); // eslint-disable-line
        /* const router = require('@/router'); */ // eslint-disable-line
          /* store.default.dispatch('accounts/logout').then(() => {
          router.default.push('/login');
        }); */
        return error;
      }
      throw error;
    },
  );

apiService.interceptors.request.use((config) => {
    //const token = localStorage.getItem('token');
    //config.headers.Authorization = token ? `Bearer ${token}` : ''; // eslint-disable-line
    return config;
});

export default apiService;